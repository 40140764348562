<template>
  <div>
    <div class="topButton">
      <div class="titleFont noButtonTitle floatLeft">檢視專案代碼</div>
    </div>
    <div class="clearBoth"></div>
    <ValidationObserver tag="form" ref="form">
      <CForm>
        <template>
          <CCard>
            <CCardHeader>專案代碼資訊</CCardHeader>
            <CCardBody>
              <div role="group" class="col-sm-8 form-group">
                <label for="uid-drab8n61hjm" class=""> 專案代碼 </label>
                <input
                  id="uid-drab8n61hjm"
                  type="text"
                  placeholder=""
                  class="form-control name"
                  disabled
                />
              </div>

              <div role="group" class="col-sm-8 form-group">
                <label for="uid-drab8n61hjm" class=""> 公司名稱 </label>
                <input
                  id="uid-drab8n61hjm"
                  type="text"
                  placeholder=""
                  class="form-control name"
                  disabled
                />
              </div>

              <div role="group" class="col-sm-8 form-group">
                <label for="uid-2x1xhd5qgt6" class=""> 上層公司 </label>
                <input
                  id="uid-2x1xhd5qgt6"
                  type="text"
                  placeholder=""
                  class="form-control address"
                  disabled
                />
              </div>
            </CCardBody>
          </CCard>
        </template>

        <CCard>
          <CCardBody>
            <div class="positionR">
              <div class="rightBtn"></div>
            </div>
            <CDataTable
              :items="billingAccountList"
              :fields="fields"
              hover
              sorter
              pagination
            >
              <template #status="{ item, index }">
                <td class="py-2">
                  {{ item.status == 1 ? '啟用' : '停用' }}
                </td>
              </template>
            </CDataTable>
            <!-- <CPagination
          :activePage.sync="get_baPage.current"
          :pages="get_baPage.pages"
          align="end"
          @click.native="get(get_baPage.current)"
          v-if="get_baPage != ''"
        /> -->
          </CCardBody>
        </CCard>
      </CForm>
    </ValidationObserver>

    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'EditAccount',
  data() {
    return {
      companyName: '',
      currenies: [
        { id: 1, name: '台幣' },
        { id: 2, name: '美金' },
      ],
      billingAccountList: [
        {
          account_id: 1,
          account_name: 'ba_name1',

          status: '1',
        },
        {
          account_id: 2,
          account_name: 'ba_name2',

          status: '1',
        },
        {
          account_id: 2,
          account_name: 'ba_name2',

          status: '1',
        },
      ],
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([]),
    fields() {
      return [
        {
          key: 'account_id',
          label: 'Billing Account ID',
        },

        {
          key: 'account_name',
          label: 'Billing Account Name',
        },

        {
          key: 'status',
          label: '狀態',
        },
      ]
    },
  },
  methods: {
    click() {
      //   console.log('test')
    },
    sendBtn() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          //   送出資料
          let data = {
            id: this.$route.params.id,
            companyName: $('.companyName').val(),
            companyAddress: $('.companyAddress').val(),
            tax: $('.tax').val(),
            principal: $('.principal').val(),
            email: $('.email').val(),
            discount: $('.discount').val(),
            bussiness_tax: $('.bussiness_tax').val(),
            service_charge: $('.service_charge').val(),
            currency: $('.currency').val(),
          }
          //   console.log('data', data)
          this.$store.dispatch('actionCompanyUpdate', data)
        } else {
          console.log('NO')
        }
      })
    },
  },
  mounted() {},
  created() {},
}
</script>
